import './App.css';
import Header from "./components/Header"
import FirstSection from './components/FirstSection.jsx';
import AboutSection from './components/AboutSection.jsx';
import NewSection from './components/NewSection.jsx';
import SecondSection from './components/SecondSection.jsx';
import ThirdSection from './components/ThirdSection.jsx';
import Footer from './components/Footer.jsx'

function App() {
  return (
    <div className="App">
      <Header />
      <div className="firstSection">
        <FirstSection />
        <ThirdSection />
        <SecondSection />
        <NewSection />
        <Footer />
      </div>
    </div>
  );
}

export default App;