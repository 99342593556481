import React from 'react';
import './ThirdSection.css';
import logo from "../images/microwave.gif"
import top from "../images/demi3.png"
import bg2 from "../images/bg2.png"
import microwave from "../images/microwave1.png"
import candle1 from "../images/candle1.png"
import candle2 from "../images/candle2.png"
import candle3 from "../images/candle3.png"

const ThirdSection = () => {
  return (
    <section className="third-section">
      <img src={top} alt="solana gif"  />
      {/* <div className="leaf">
                <div>  <img src={microwave} height="75px" width="75px"></img></div>
                <div><img src={microwave} height="75px" width="75px"></img></div>
                <div>  <img src={microwave} height="75px" width="75px" ></img></div>
                <div><img src={microwave} height="75px" width="75px"></img></div>
                <div> <img src={microwave} height="75px" width="75px"></img></div>
                <div>   <img src={microwave} height="75px" width="75px"/></div>
                <div><img src={microwave} height="75px" width="75px"/></div>

            </div> */}

            <div className="leaf leaf1">
                <div>  <img src={microwave} height="75px" width="75px"></img></div>
                <div><img src={candle1} height="75px" width="75px"></img></div>
                <div>  <img src={candle2} height="75px" width="75px" ></img></div>
                <div><img src={candle3} height="75px" width="75px"></img></div>
                <div> <img src={microwave} height="75px" width="75px"></img></div>
                <div>   <img src={candle1} height="75px" width="75px"/></div>
                <div><img src={candle2} height="75px" width="75px"/></div>

            </div>

            {/* <div className="leaf leaf2">
                <div>  <img src={microwave} height="75px" width="75px"></img></div>
                <div><img src={microwave} height="75px" width="75px"></img></div>
                <div>  <img src={microwave} height="75px" width="75px" ></img></div>
                <div><img src={microwave} height="75px" width="75px"></img></div>

                <div> <img src={microwave} height="75px" width="75px"></img></div>
                <div>   <img src={microwave} height="75px" width="75px"/></div>
                <div><img src={microwave} height="75px" width="75px"/></div>

            </div> */}
            <h3>
            En las depths of internet lore, where memes dance with the demons of imagination, there exists a tale of Pepe, once a humble frog, now a twisted icon of chaos and hilarity. Gather 'round, ye mortals, and hear the tale of Pepe the Frognomicon!

In the dark swamps of cyberspace, where keyboards click like sinister whispers, there lived a frog named Pepe. But beware, for this was no ordinary amphibian; he was a creature of memetic madness, a harbinger of hilarity, and a patron saint of mischief.

With his mischievous grin and hypnotic gaze, Pepe slithered through the digital shadows, leaving chaos and laughter in his wake. His memes were the stuff of legend, spreading like wildfire across the web, corrupting minds and tickling funny bones with equal fervor.

But alas, not all were amused by Pepe's antics. The forces of order and decency sought to banish him from the realm of cyberspace, to extinguish his memetic flame and restore peace to the digital kingdom. Yet, try as they might, Pepe proved elusive, a slippery trickster who danced just out of reach, taunting his would-be captors with his irreverent wit.

And so, dear reader, the saga of Pepe the Frognomicon continues to this day, a testament to the power of laughter and the eternal struggle between chaos and order. So the next time you see a Pepe meme slithering across your screen, remember: laughter is the devil's music, and Pepe is the pied piper of internet mayhem.
            </h3>

      <h1></h1>
    </section>
  );
};

export default ThirdSection;
