import React from 'react';
import './SecondSection.css';
import logo from "../images/microwave.gif"
import top from "../images/top.png"

const SecondSection = () => {
  return (
    <section className="second-section">
      <img src={top} alt="solana gif" className="background-image" />
    </section>
  );
};

export default SecondSection;
