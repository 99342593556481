import React from 'react';
import './FirstSection.css';
import backgroundImage from '../images/bot.png'; 


const FirstSection = () => {
  return (
    <section className="first-section" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="content">
        <div className="image">
          <h2>DEMI</h2>
          <a href="https://sunpump.meme/" target='_blank' rel="noopener noreferrer">
            <div className='image-container' />
          </a>
          <h3>The cute demon on Tron is here</h3>
        </div>
      </div>
    </section>
  );
};

export default FirstSection;